import { navigate } from 'gatsby'
import React from 'react'

export default function Page() {

  if (typeof window !== "undefined") navigate("/villas");

  return (
    <div/>
  )
}
